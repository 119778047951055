
import { Component, Prop, Vue } from 'vue-property-decorator'
import { GiftCardRate } from '@/models'

@Component({
  name: 'GiftCardRatesCard'
})
export default class extends Vue {
  @Prop({ required: true }) private giftCardRate!: GiftCardRate

  giftCardRateSelected: GiftCardRate | null = null

  selectGiftCardDiscount(giftCardRate: GiftCardRate | null) {
    this.giftCardRateSelected = giftCardRate
    this.$emit('giftCardRateSelected', giftCardRate)
  }
}
