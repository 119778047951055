
import { Component, Vue } from 'vue-property-decorator'
import { GiftCard, Seller } from '@/models'

@Component({
  name: 'GiftCardList',
  components: {
    CardGiftCard: () => import('./CardGiftCard.vue')
  }
})
export default class extends Vue {
  private giftCards: GiftCard[] = []
  private giftCardSelected: GiftCard | null = null
  private giftCardSelectedIndex = 0
  private giftCardsLoading = false
  private totalGiftCards = 0

  private sellers: Seller[] = []
  private sellersLoading = false

  private listQuery: any = {
    giftCardName: '',
    sellerId: '',
    page: 1
  }

  created() {
    this.getGiftCards()
  }

  private async getGiftCards() {
    this.giftCardsLoading = true

    const { data, meta } =
        await GiftCard
            .where({
              sellerId: this.listQuery.sellerId,
              name: { prefix: this.listQuery.giftCardName }
            })
            .includes('defaultGiftCardSeller')
            .page(this.listQuery.page)
            .per(20)
            .order({ name: 'asc' })
            .stats({ total: 'count' })
            .all()

    this.giftCards.push(...data)
    this.totalGiftCards = meta.stats.total.count
    this.listQuery.page += 1

    if (data.length > 0) {
      this.giftCardSelected = this.giftCards[0]
      this.$emit('selected', {
        giftCard: this.giftCardSelected,
        index: this.giftCardSelectedIndex
      })
    }

    this.giftCardsLoading = false
  }

  async getSellers(query: string) {
    this.sellersLoading = true
    const { data } = await Seller
        .where({ name: { prefix: query } })
        .page(1)
        .per(20)
        .all()

    this.sellers = data
    this.sellersLoading = false
  }

  handleSearchGiftCard() {
    this.listQuery.page = 1
    this.giftCards = []
    this.getGiftCards()
  }

  handleSelectSeller(sellerId: string) {
    const seller = this.sellers.find(e => e.id === sellerId)

    if (seller) this.listQuery.sellerId = seller.id
    else this.listQuery.sellerId = ''

    this.handleSearchGiftCard()
  }

  private seletectOperation(giftCard: GiftCard, index: number): void {
    this.giftCardSelected = giftCard
    this.giftCardSelectedIndex = index
    this.$emit('selected', {
      giftCard: this.giftCardSelected,
      index: this.giftCardSelectedIndex
    })
  }

  private resetFilters(): void {
    this.listQuery = {
      giftCardName: '',
      sellerId: '',
      page: 1
    }
    this.giftCards = []
    this.getGiftCards()
  }
}
