
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Rate } from "@/models"

@Component({
  name: 'RateScheduleCard',
  components: {
    RateSchedulePopover: () => import('./RateSchedulePopover.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) rate!: Rate
}
