
import { Component, Prop, Vue } from 'vue-property-decorator'
import { GiftCardDiscount, GiftCardRate } from '@/models'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'RateAsideSettings',
  components: {
    AddDiscountPopover: () => import('./add-discount/AddDiscount.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true, default: null }) giftCardRatesSelected!: GiftCardRate | null

  private tempGiftCardDiscount: GiftCardDiscount = new GiftCardDiscount({ discount: 0 })

  private scrollPosition = 90
  private updateDiscount = false

  get scrollHeight() {
    return this.scrollPosition
  }

  private validateRate = (rule: any, value: number, callback: any) => {
    if (value > this.giftCardRatesSelected!.rate) {
      callback(new Error('Le taux de remise est trop élévé'))
    } else {
      callback()
    }
  }

  private rules = {
    discount: [
      { required: true, message: 'Le taux de remise est requis', trigger: 'blur' },
      { validator: this.validateRate, trigger: 'blur' }
    ]
  }

  private setTempGiftCardDiscount(giftCardDiscount: GiftCardDiscount) {
    this.tempGiftCardDiscount = giftCardDiscount.dup()
  }

  private updateCustomerProgram(formIndex: number) {
    (this.$refs[`discountForm_${formIndex}`] as any)[0].validate(async(valid: boolean) => {
      this.updateDiscount = true

      if (valid) {
        const data = this.tempGiftCardDiscount

        try {
          await data.save()

          this.$notify({
            title: 'Taux de remise mis à jour',
            message: `Le taux de remise de ${this.giftCardRatesSelected!.giftCard.name} sur le programme ${data.program.name} est de ${data.discount}%`,
            type: 'success',
            duration: 2000
          })
          this.updateDiscountList(data)
          ;(this.$refs[`popover_${formIndex}`] as any)[0].doClose()
        } catch (err) {
          this.updateDiscount = false
        }
      }
      this.updateDiscount = false
    })
  }

  private updateDiscountList(giftCardDiscount: GiftCardDiscount): void {
    const index = this.giftCardRatesSelected!.discounts.findIndex(discount => discount.id === this.tempGiftCardDiscount.id)
    this.giftCardRatesSelected!.discounts.splice(index, 1, giftCardDiscount)
  }

  private handleCreateDiscount(giftCardDiscount: GiftCardDiscount): void {
    this.giftCardRatesSelected!.discounts.splice(0, 0, giftCardDiscount)
  }

  private async handleDeleteDiscount(giftCardDiscount: GiftCardDiscount, index: number): Promise<void> {
    await giftCardDiscount.destroy()

    if (Object.keys(giftCardDiscount.errors).length > 0) {
      this.printErrors(giftCardDiscount.errors)
    } else {
      this.$notify({
        title: 'Taux de remise supprimé',
        message: `${this.giftCardRatesSelected!.giftCard.name} a été retiré du programme ${giftCardDiscount.program.name}`,
        type: 'success',
        duration: 2000
      })

      this.giftCardRatesSelected!.discounts.splice(index, 1)
      if (this.giftCardRatesSelected!.discounts.length === 0) this.$emit('closeAside', this.giftCardRatesSelected)
    }
  }

  private printErrors(errors: any) {
    const e: (IValidationError<GiftCardDiscount> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }

  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  }

  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  }

  private updateScroll() {
    this.scrollPosition = window.scrollY === 0 ? 90 : window.scrollY >= 70 ? 15 : 60
  }
}
