
import { Component, Prop, Vue } from 'vue-property-decorator'
import { GiftCard } from '@/models'

@Component({
  name: 'CardOperation'
})
export default class extends Vue {
  @Prop({ required: true }) private giftCard!: GiftCard
  @Prop({ required: true }) private giftCardSelected!: GiftCard
}
