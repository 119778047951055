
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GiftCard, PurchaseInfo, Rate, RateChangeSchedule, Wholesaler } from "@/models";

import Pagination from '@/components/Pagination/index.vue'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'BuyersPurchaseInfosTable',
  components: {
    Pagination,
    PurchaseInfosDialog:() => import('./PurchaseInfosDialog.vue'),
    RateSchedulePopover: () => import('./RateSchedulePopover.vue'),
    RateScheduleCard: () => import('./RateScheduleCard.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private parentPurchaseInfo!: PurchaseInfo
  @Prop({ required: true }) private giftCard!: GiftCard
  @Prop({ required: true }) private visible!: boolean

  userStore: any = useUserStore()

  private buyersPurchaseInfos: PurchaseInfo[] = []
  private totalBuyersPurchaseInfos = 0
  private buyersPurchaseInfoLoading = false

  private showPurchaseInfoDialog = false
  private buyersPurchaseInfoToEdit: PurchaseInfo | null = null

  private pagination = {
    page: 1,
    limit: 20
  }

  private apiEnabledText: any = {
    true: this.$t('payVat.yes'),
    false: this.$t('payVat.no'),
  }

  private payVatText: any = {
    vat_paid: this.$t('payVat.yes'),
    vat_not_paid: this.$t('payVat.no'),
    vat_irrelevant: this.$t('payVat.intracom')
  }

  private offsetText: any = {
    complete_offset: this.$t('offset.complete'),
    partial_offset: this.$t('offset.partial'),
    no_offset: this.$t('offset.no')
  }

  private paymentModeText: any = {
    prepaid: this.$t('paymentMode.prepaid'),
    differed: this.$t('paymentMode.differed')
  }

  get wholesaler(): Wholesaler {
    return this.userStore.model.manageable
  }

  @Watch('visible', { immediate: true })
  async getBuyersPurchaseInfo(value: boolean) {
    if (value) {
      this.buyersPurchaseInfoLoading = true

      const { data, meta } =
          await PurchaseInfo
              .where({ sellerId: this.parentPurchaseInfo.seller.id })
              .where({ wholesalerId: this.wholesaler.id })
              .where({ giftCardId: this.giftCard.id })
              .includes(['buyer', { rate: ['rateCard', 'rateChangeSchedules'] }])
              .page(this.pagination.page)
              .per(this.pagination.limit)
              .stats({ total: 'count' })
              .order({ createdAt: 'desc' })
              .all()

      this.buyersPurchaseInfos = data.filter(e => e.id !== this.parentPurchaseInfo.id)
      this.totalBuyersPurchaseInfos = meta.stats.total.count
      this.buyersPurchaseInfoLoading = false
    }
  }

  addInList(purchaseInfo: PurchaseInfo): void {
    this.buyersPurchaseInfos.unshift(purchaseInfo)
  }

  async deleteInList(purchaseInfo: PurchaseInfo, index: number): Promise<void> {
    try {
      await purchaseInfo.destroy()
      this.buyersPurchaseInfos.splice(index, 1)
      this.$notify({
        title: this.$t('delete.notification.success.title') as string,
        message: this.$t('delete.notification.success.subtitle') as string,
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      this.$notify({
        title: this.$t('delete.notification.error.title') as string,
        message: this.$t('delete.notification.error.subtitle') as string,
        type: 'error',
        duration: 2000
      })
    }
  }

  public openUpdateDialog(purchaseInfo: PurchaseInfo): void {
    this.buyersPurchaseInfoToEdit = purchaseInfo.dup()
    this.showPurchaseInfoDialog = true
  }

  updateInList(purchaseInfo: PurchaseInfo): void  {
    const index = this.buyersPurchaseInfos.findIndex((data: PurchaseInfo) => data.id == purchaseInfo.id)
    if (index !== -1) this.buyersPurchaseInfos.splice(index, 1, purchaseInfo.dup())
    this.buyersPurchaseInfoToEdit = null
  }

  getRelativePurchaseInfo(rateId: string): number {
    return this.buyersPurchaseInfos.findIndex((purchaseInfo: PurchaseInfo) => purchaseInfo.rate.id === rateId)
  }

  handleCreatedRateSchedule(rateSchedule: RateChangeSchedule): void {
    const purchaseInfoIndex = this.getRelativePurchaseInfo(rateSchedule.rate.id as string)
    if (purchaseInfoIndex !== -1) this.buyersPurchaseInfos[purchaseInfoIndex].rate.rateChangeSchedules.push(rateSchedule)
  }

  handleUpdatedRateSchedule(rateSchedule: RateChangeSchedule, index: number): void {
    const purchaseInfoIndex = this.getRelativePurchaseInfo(rateSchedule.rate.id as string)
    if (purchaseInfoIndex !== -1) this.buyersPurchaseInfos[purchaseInfoIndex].rate.rateChangeSchedules.splice(index, 1, rateSchedule)
  }

  handleDeleteRateSchedule(rateSchedule: RateChangeSchedule, index: number, rate: Rate): void {
    const purchaseInfoIndex = this.getRelativePurchaseInfo(rate.id as string)

    if (purchaseInfoIndex !== -1) {
      try {
        rateSchedule.destroy()
        this.buyersPurchaseInfos[purchaseInfoIndex].rate.rateChangeSchedules.splice(index, 1)

        this.$notify({
          title: this.$t('purchaseInfo.rateChangeSchedule.card.notification.success.title') as string,
          message: this.$t('purchaseInfo.rateChangeSchedule.card.notification.success.subtitle') as string,
          type: 'success',
          duration: 2000
        })
      } catch (e) {
        this.$notify({
          title: this.$t('purchaseInfo.rateChangeSchedule.card.notification.error.title') as string,
          message: this.$t('purchaseInfo.rateChangeSchedule.card.notification.error.subtitle') as string,
          type: 'error',
          duration: 2000
        })
      }
    }
  }

  closePopup(index: number): void {
    const popover = this.$refs[`popover_${index}`] as any
    if (popover && popover?.length > 0) (popover[0] as any).doClose()
  }
}
