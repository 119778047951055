
import { Component, Vue } from 'vue-property-decorator'
import { GiftCardRate } from '@/models'

import GiftCardRatesCard from './components/GiftCardRatesCard.vue'
import Pagination from '@/components/Pagination/index.vue'

@Component({
  name: 'RatesManagement',
  components: {
    Pagination,
    GiftCardRatesCard,
    FiltersDrawer: () => import('./components/FiltersDrawer.vue'),
    RateAsideSettings: () => import('./components/RateAsideSettings.vue'),
    RatesManagementHeader: () => import('./components/RatesManagementHeader.vue')
  }
})
export default class extends Vue {
  private giftCardRates: GiftCardRate[] = []
  private giftCardRatesSelected: GiftCardRate | null = null

  private totalgiftCardRate = 0
  private giftCardRatesLoading = true

  private listQuery = {
    giftCardId: '',
    page: 1,
    limit: 20
  }

  async created() {
    await this.getRates()
  }

  private async getRates() {
    const { data, meta } = await GiftCardRate
      .where({ giftCardId: this.listQuery.giftCardId })
      .includes(['giftCard', { discounts: ['program'] }])
      .page(this.listQuery.page)
      .per(this.listQuery.limit)
      .stats({ total: 'count' })
      .all()

    this.giftCardRates = data
    this.totalgiftCardRate = meta.stats.total.count
    this.giftCardRatesLoading = false
  }

  private setGiftCardRatesSelected(giftCardRate: GiftCardRate | null) {
    if (this.giftCardRatesSelected?.id && this.giftCardRatesSelected?.id !== giftCardRate?.id) {
      (this.$refs[`card_${this.giftCardRatesSelected.id}`] as any)[0].giftCardRateSelected = null
    }
    this.giftCardRatesSelected = giftCardRate
  }

  private closeRateSettings(giftCardRate: GiftCardRate) {
    this.giftCardRatesSelected = null
    ;(this.$refs[`card_${giftCardRate.id}`] as any)[0].selectGiftCardDiscount(null)
  }

  private setFilters(filters: any) {
    this.listQuery = { ...filters }
    this.refresh()
  }

  private refresh() {
    if (this.giftCardRatesSelected?.id) {
      (this.$refs[`card_${this.giftCardRatesSelected.id}`] as any)[0].selectGiftCardDiscount(null)
      this.giftCardRatesSelected = null
    }
    this.giftCardRatesLoading = true
    this.getRates()
  }
}
