
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PurchaseInfo } from '@/models'

@Component({
  name: 'CharacteristicDeferredAmountTable'
})
export default class extends Vue {
  @Prop({ required: true }) private purchaseInfo!: PurchaseInfo

  private deferredDeadlineText: any = {
    at_invoice_reception: this.$t('deferredDeadline.atInvoiceReception'),
    at_end_of_month: this.$t('deferredDeadline.atEndOfMonth'),
  }
}
