
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'PurchaseInfos',
  components: {
    PurchaseInfosHeader: () => import('./components/PurchaseInfosHeader.vue'),
    PurchaseInfosWrapper: () => import('./components/PurchaseInfosWrapper/index.vue')
  }
})
export default class extends Vue {
}
