
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PurchaseInfo, Buyer, Rate, Wholesaler, GiftCard, RateCard, Seller } from '@/models'
import { Form } from 'element-ui'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'PurchaseInfosDialog',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue'),
    DirectUpload: () => import('@/components/DirectUpload/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private visible!: boolean
  @Prop({ required: true }) private purchaseInfoStatic!: PurchaseInfo
  @Prop({ required: true }) private giftCard!: GiftCard
  @Prop({ required: false, default: null }) private purchaseInfo!: any

  userStore: any = useUserStore()

  private tempData: PurchaseInfo = new PurchaseInfo({
    buyer: new Buyer({ id: '' }),
    wholesaler: new Wholesaler({ id: '' }),
    seller: new Seller({ id: '' }),
    rate: new Rate({ id: '', requirementOperator: 'lt', amount: 0 }),
    paymentMode: 'prepaid',
    payVat: 'vat_paid',
    deferredDeadline: 'at_end_of_month',
    offset: 'no_offset',
    vatPayback: 'no_vat_payback'
  })

  private loading = false

  private buyers: Buyer[] = []
  private buyersLoading = true

  private rateCard: RateCard = new RateCard({ id: '' })
  private rateCardsLoading = true

  dialogStatus = 'create'
  textMap: any = {
    create: this.$t('purchaseInfoBuyerDialog.createTitle'),
    update: this.$t('purchaseInfoBuyerDialog.updateTitle')
  }

  private payVatOptions = [
    { name: this.$t('payVat.yes'), value: 'vat_paid' },
    { name: this.$t('payVat.no'), value: 'vat_not_paid' },
    { name: this.$t('payVat.intracom'), value: 'vat_irrelevant' }
  ]

  private offsetOptions = [
    { name: this.$t('offset.complete'), value: 'complete_offset' },
    { name: this.$t('offset.partial'), value: 'partial_offset' },
    { name: this.$t('offset.no'), value: 'no_offset' }
  ]

  private partialModeOffsetOptions = [
    { name: this.$t('partialModeOffset.instantPartial'), value: 'instant_partial_offset' },
    { name: this.$t('partialModeOffset.differedPartial'), value: 'differed_partial_offset' },
    { name: this.$t('partialModeOffset.manualPartial'), value: 'manual_partial_offset' }
  ]

  private vatPaybackOptions = [
    { name: this.$t('vatPayBack.bankTransfer'), value: 'bank_transfer_vat_payback' },
    { name: this.$t('vatPayBack.prepaidAccount'), value: 'prepaid_account_vat_payback' },
    { name: this.$t('vatPayBack.instantPartial'), value: 'instant_partial_offset' },
    { name: this.$t('vatPayBack.no'), value: 'no_vat_payback' },
  ]

  private paymentModeOptions = [
    { name: this.$t('paymentMode.prepaid'), value: 'prepaid' },
    { name: this.$t('paymentMode.differed'), value: 'differed' }
  ]

  get wholesaler(): Wholesaler {
    return this.userStore.model.manageable
  }

  async created() {
    await this.getBuyers()
    await this.getRateCards()
    !this.purchaseInfo ? this.handleCreate() : this.handleUpdate()
  }

  private async getBuyers() {
    this.buyersLoading = true

    const { data } = await Buyer.page(1).per(999).all()

    this.buyers = data
    this.buyersLoading = false
  }

  private async getRateCards() {
    this.rateCardsLoading = true
    const { data } = await RateCard
        .includes(['rates'])
        .where({ manageableId: this.wholesaler.id })
        .per(999)
        .page(1)
        .order({ createdAt: 'desc' })
        .all()

    if (this.dialogStatus === 'create')
      this.rateCard = data.length > 0 ? data[0] : new RateCard({ id: '' })
    this.rateCardsLoading = false
  }

  private handleCreate() {
    this.setTempData()
    this.dialogStatus = 'create'
    this.$nextTick(() => {
      (this.$refs.purchaseInfoForm as Form).clearValidate()
    })
  }

  private handleUpdate() {
    this.tempData = this.purchaseInfo.dup()

    this.dialogStatus = 'update'
    this.$nextTick(() => {
      (this.$refs.purchaseInfoForm as Form).clearValidate()
    })
  }

  private handleSelectBuyer(buyerId: string): void {
    const buyer = this.buyers.find((buyer: Buyer) => buyer.id === buyerId)
    this.tempData.buyer = buyer as Buyer
  }

  createOrUpdate() {
    (this.$refs.purchaseInfoForm as Form).validate(async(valid) => {
      this.loading = true
      if (valid) {

        try {
          await this.manageBuyerRate()
          let with_ = this.dialogStatus === 'create' ? ['rate.id', 'buyer.id', 'giftCard.id', 'seller.id', 'wholesaler.id'] : ['rate.id', 'buyer.id']

          await this.tempData.save({ with: with_ })

          if (this.dialogStatus === 'create') {
            this.$emit('created', this.tempData.dup())
            this.$notify({
              title: this.$t('purchaseInfoBuyerDialog.form.notification.success.title') as string,
              message: this.$t('purchaseInfoBuyerDialog.form.notification.success.subtitle') as string,
              type: 'success',
              duration: 2000
            })
          } else {
            this.$emit('update', this.tempData)
            this.$notify({
              title: this.$t('purchaseInfoBuyerDialog.form.notification.update.title') as string,
              message: this.$t('purchaseInfoBuyerDialog.form.notification.update.subtitle') as string,
              type: 'success',
              duration: 2000
            })
          }
          this.setTempData()
          this.$emit('close')
        } catch (err) {
          this.$notify({
            title: this.$t('purchaseInfoBuyerDialog.form.notification.error.title') as string,
            message: this.$t('purchaseInfoBuyerDialog.form.notification.error.subtitle') as string,
            type: 'error',
            duration: 2000
          })
          this.loading = false
        }
      }
      this.loading = false
    })
  }

  private async manageBuyerRate(): Promise<void> {
    let rate_ = this.tempData.rate

    await rate_.save({ with: 'rateCard.id' })
    this.tempData.rate = rate_
  }

  setTempData() {
    this.tempData = new PurchaseInfo({
      buyer: new Buyer({ id: '' }),
      wholesaler: this.wholesaler.dup(),
      giftCard: this.giftCard.dup(),
      seller: this.purchaseInfoStatic.seller.dup(),
      rate: new Rate({
        id: '',
        requirementOperator: 'lt_or_eq',
        requirementValue: null,
        amount: this.purchaseInfoStatic.rate.amount,
        rateCard: this.rateCard.dup()
      }),
      paymentMode: 'prepaid',
      deferredDeadline: 'at_end_of_month',
      payVat: 'vat_paid',
      offset: 'no_offset',
      vatPayback: 'no_vat_payback',
      apiEnabled: false
    })
  }
}
