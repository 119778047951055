
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { PurchaseInfo, Rate, RateChangeSchedule } from '@/models'
import { Form } from 'element-ui'

@Component({
  name: 'RateSchedulePopover'
})
export default class extends Vue {
  @Prop({ required: false }) rate!: Rate
  @Prop({ required: false }) rateScheduleToEdit!: RateChangeSchedule
  @Prop({ required: false }) buyersPurchaseInfos!: PurchaseInfo[]
  @Prop({ required: true }) isSellerRate!: boolean
  @Prop({ required: false, default: false }) editMode!: boolean

  private tempRateSchedule: RateChangeSchedule = new RateChangeSchedule({ newAmount: 0, rate: new Rate() })
  private loading = false

  private idForm = new Date()
  private visible = false

  private rules = {
    newAmount: [
      { required: true, message: this.$t('purchaseInfo.rateChangeSchedule.popover.rules.amount'), trigger: 'blur' }
    ],
    applicableAt: [
      { required: true, message: this.$t('purchaseInfo.rateChangeSchedule.popover.rules.time'), trigger: 'blur' }
    ],
    'rate.id': [
      { required: !this.isSellerRate, message: this.$t('purchaseInfo.rateChangeSchedule.popover.rules.buyer'), trigger: 'blur' }
    ],
  }

  @Watch('visible')
  setTempRateSchedule(visible: boolean): void {
    if (visible) {
      this.tempRateSchedule = new RateChangeSchedule({ newAmount: 0, rate: new Rate() })
      ;(this.$refs[`form_rate_${this.idForm}`] as Form).clearValidate()

      if (this.editMode) this.tempRateSchedule = this.rateScheduleToEdit!.dup()
      if (this.rate) this.tempRateSchedule.rate = this.rate.dup()
      if (this.buyersPurchaseInfos?.length > 0) this.tempRateSchedule.rate = this.buyersPurchaseInfos[0].rate.dup()
    }
  }

  async save(): Promise<void> {
    (this.$refs[`form_rate_${this.idForm}`] as Form).validate(async(valid) => {
      if (valid) {
        this.loading = true

        try {
          await this.tempRateSchedule.save({ with: 'rate.id' })

          if (this.editMode) {
            this.$emit('update', this.tempRateSchedule.dup())
            this.$notify({
              title: this.$t('purchaseInfo.rateChangeSchedule.popover.notification.update.title') as string,
              message: this.$t('purchaseInfo.rateChangeSchedule.popover.notification.update.subtitle') as string,
              type: 'success',
              duration: 2000
            })
          } else {
            this.$emit('create', this.tempRateSchedule.dup())
            this.$notify({
              title: this.$t('purchaseInfo.rateChangeSchedule.popover.notification.success.title') as string,
              message: this.$t('purchaseInfo.rateChangeSchedule.popover.notification.success.subtitle') as string,
              type: 'success',
              duration: 2000
            })
          }
          this.visible = false
        } catch (e) {
          this.$notify({
            title: this.$t('purchaseInfo.rateChangeSchedule.popover.notification.error.title') as string,
            message: this.$t('purchaseInfo.rateChangeSchedule.popover.notification.error.subtitle') as string,
            type: 'error',
            duration: 2000
          })
        }
        this.loading = false
      }
    })
  }

  private handleSelectBuyerRate(rateId: string): void {
    const buyerPurchaseInfo = this.buyersPurchaseInfos.find((buyerPurchaseInfo: PurchaseInfo) => buyerPurchaseInfo.rate.id === rateId)
    this.tempRateSchedule.rate = buyerPurchaseInfo?.rate as Rate
  }
}
