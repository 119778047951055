
import { Component, Vue } from 'vue-property-decorator'
import { GiftCard, PurchaseInfo, RateChangeSchedule, Wholesaler } from "@/models";

import GiftCardsList from './components/GiftCardsList.vue'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'PurchaseInfosWrapper',
  components: {
    GiftCardsList,
    GeneralInformationTable: () => import('./components/GeneralInformationTable.vue'),
    CharacteristicDeferredAmountTable: () => import('./components/CharacteristicDeferredAmountTable.vue'),
    BuyersPurchaseInfosTable: () => import('./components/BuyersPurchaseInfosTable.vue'),
    PurchaseInfoSellerDialog: () => import('./components/PurchaseInfoSellerDialog.vue')
  }
})
export default class extends Vue {
  userStore: any = useUserStore()

  private selectedGiftCard: GiftCard | null = null
  private selectedGiftCardIndex: number | null = null

  private purchaseInfos: PurchaseInfo[] = []
  private purchaseInfosLoading = false

  private selectedPurchaseInfo: PurchaseInfo | null = null

  private showSellerDialog = false
  private purchaseInfoSellerToEdit: PurchaseInfo | null = null

  private activeTab = ''

  get wholesaler(): Wholesaler {
    return this.userStore.model.manageable
  }

  private selectGiftCard({ giftCard, index }: any): void {
    this.selectedGiftCard = giftCard
    this.selectedGiftCardIndex = index
    this.getPurchaseInfosByGiftCard(giftCard.id)
  }

  private async getPurchaseInfosByGiftCard(giftCardId: string): Promise<void> {
    this.purchaseInfosLoading = true
    const { data } =
        await PurchaseInfo
            .includes(['buyer', { rate: ['rateCard', 'rateChangeSchedules'] }, 'seller', 'giftCard'])
            .where({ wholesalerId: this.wholesaler.id })
            .where({ giftCardId })
            .where({ buyerId: 'null' })
            .selectExtra(['deferredAmountAvailable'])
            .all()

    this.purchaseInfos = data.sort((a: PurchaseInfo, b: PurchaseInfo) =>
      (a.seller.id === this.selectedGiftCard?.defaultGiftCardSeller.sellerId) ? 1 : -1).reverse()

    if (data.length > 0) {
      this.selectedPurchaseInfo = data[0]
    }
    this.purchaseInfosLoading = false
  }

  handleCreatedPurchaseInfo(purchaseInfo: PurchaseInfo): void {
    this.purchaseInfos.unshift(purchaseInfo)
    this.selectedPurchaseInfo = purchaseInfo
    this.purchaseInfoSellerToEdit = null
    this.activeTab = `${purchaseInfo.id}`
  }

  handleUpdatePurchaseInfo(purchaseInfo: PurchaseInfo): void {
    const index = this.purchaseInfos.findIndex((purchaseInfoItem: PurchaseInfo) => purchaseInfoItem.id === purchaseInfo.id)
    this.purchaseInfos.splice(index, 1, purchaseInfo)
    this.purchaseInfoSellerToEdit = null
  }

  getRelativePurchaseInfo(): number {
    return this.purchaseInfos.findIndex((purchaseInfo: PurchaseInfo) => purchaseInfo.id === this.selectedPurchaseInfo!.id)
  }

  handleCreatedRateSchedule(rateSchedule: RateChangeSchedule): void {
    const purchaseInfoIndex = this.getRelativePurchaseInfo()
    if (purchaseInfoIndex !== -1) this.purchaseInfos[purchaseInfoIndex].rate.rateChangeSchedules.push(rateSchedule)
  }

  handleUpdatedRateSchedule(rateSchedule: RateChangeSchedule, index: number): void {
    const purchaseInfoIndex = this.getRelativePurchaseInfo()
    if (purchaseInfoIndex !== -1) this.purchaseInfos[purchaseInfoIndex].rate.rateChangeSchedules.splice(index, 1, rateSchedule)
  }

  handleDeleteRateSchedule(rateSchedule: RateChangeSchedule, index: number): void {
    const purchaseInfoIndex = this.getRelativePurchaseInfo()

    if (purchaseInfoIndex !== -1) {
      try {
        rateSchedule.destroy()
        this.purchaseInfos[purchaseInfoIndex].rate.rateChangeSchedules.splice(index, 1)

        this.$notify({
          title: this.$t('purchaseInfo.rateChangeSchedule.card.notification.success.title') as string,
          message: this.$t('purchaseInfo.rateChangeSchedule.card.notification.success.subtitle') as string,
          type: 'success',
          duration: 2000
        })
      } catch (e) {
        this.$notify({
          title: this.$t('purchaseInfo.rateChangeSchedule.card.notification.error.title') as string,
          message: this.$t('purchaseInfo.rateChangeSchedule.card.notification.error.subtitle') as string,
          type: 'error',
          duration: 2000
        })
      }
    }
  }

  private handleChangePurchaseInfoSelected(purchaseInfoId: string): void {
    if (purchaseInfoId) this.selectedPurchaseInfo = this.purchaseInfos.find((purchaseInfo: PurchaseInfo) => purchaseInfo.id === purchaseInfoId) as PurchaseInfo
    else this.selectedPurchaseInfo = null
  }

  closePopup(index: number): void {
    const popover = this.$refs[`popover_${index}`] as any
    if (popover && popover?.length > 0) (popover[0] as any).doClose()
  }

  async deleteInList(purchaseInfo: PurchaseInfo, index: number): Promise<void> {
    try {
      await purchaseInfo.destroy()
      this.purchaseInfos.splice(index, 1)
      this.$notify({
        title: this.$t('delete.notification.success.title') as string,
        message: this.$t('delete.notification.success.subtitle') as string,
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      this.$notify({
        title: this.$t('delete.notification.error.title') as string,
        message: this.$t('delete.notification.error.subtitle') as string,
        type: 'error',
        duration: 2000
      })
    }
  }
}
