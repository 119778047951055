
import { Component, Prop, Vue } from 'vue-property-decorator'
import { GiftCardDiscount, GiftCardRate, Program } from '@/models'
import { Form } from 'element-ui'

@Component({
  name: 'AddDiscountPopover'
})
export default class extends Vue {
  @Prop({ required: true, default: null }) giftCardRateSelected!: GiftCardRate | null

  private tempGiftCardDiscount: GiftCardDiscount = new GiftCardDiscount({ discount: 0 })

  private programs: Program[] = []
  private programsLoading = false
  private createDiscount = false

  private validateRate = (rule: any, value: number, callback: any) => {
    if (value > this.giftCardRateSelected!.rate) {
      callback(new Error('Le taux de remise est trop élévé'))
    } else {
      callback()
    }
  }

  private rules = {
    discount: [
      { required: true, message: 'Le taux de remise est requis', trigger: 'blur' },
      { validator: this.validateRate, trigger: 'blur' }
    ],
    program: [
      { required: true, message: 'Le programme client est requis', trigger: 'blur' }
    ]
  }

  async created(): Promise<void> {
    await this.getPrograms()
  }

  private setTempGiftCardDiscount() {
    this.tempGiftCardDiscount = new GiftCardDiscount({ discount: 0 })
  }

  private async getPrograms() {
    this.programsLoading = true

    const { data } = await Program
      .page(1)
      .per(999)
      .all()

    this.programs.push(...data)

    this.programsLoading = false
  }

  private addCustomerProgram() {
    (this.$refs[`discountForm_add_${this.giftCardRateSelected!.giftCard.id}`] as Form).validate(async(valid: boolean) => {
      this.createDiscount = true

      if (valid) {
        if (this.giftCardRateSelected) this.tempGiftCardDiscount.rate = this.giftCardRateSelected
        const data = this.tempGiftCardDiscount

        try {
          await data.save({ with: ['program.id', 'rate.id'] })

          this.$notify({
            title: 'Taux de remise créée',
            message: `Le programme ${data.program.name} à ${data.discount}% de remise sur l'enseigne ${this.giftCardRateSelected!.giftCard.name}`,
            type: 'success',
            duration: 2000
          })
          this.$emit('created', data)
          ;(this.$refs[`popover_add_${this.giftCardRateSelected!.giftCard.id}`] as any).doClose()
        } catch (err) {
          this.createDiscount = false
        }
      }
      this.createDiscount = false
    })
  }
}
