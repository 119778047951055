
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PurchaseInfo, Buyer, Rate, Wholesaler, GiftCard, RateCard, Seller } from '@/models'
import { Form } from 'element-ui'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'PurchaseInfoSellerDialog',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue'),
    DirectUpload: () => import('@/components/DirectUpload/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private visible!: boolean
  @Prop({ required: true }) private giftCard!: GiftCard
  @Prop({ required: true, default: null }) private purchaseInfoSeller!: PurchaseInfo | null

  userStore: any = useUserStore()

  private tempData: PurchaseInfo = new PurchaseInfo({
    buyer: new Buyer({ id: '' }),
    wholesaler: new Wholesaler({ id: '' }),
    seller: new Seller({ id: '' }),
    rate: new Rate({
      id: '',
      requirementOperator: 'lt',
      amount: 0,
      rateCard: new RateCard({ id: '' })
    }),
    paymentMode: 'prepaid',
    payVat: 'vat_paid',
    offset: 'no_offset',
    vatPayback: 'no_vat_payback'
  })

  private loading = false

  private sellers: Seller[] = []
  private sellersLoading = true

  private rateCardsLoading = true


  dialogStatus = 'create'
  textMap: any = {
    create: `${this.$t('purchaseInfoBuyerDialog.createTitleSeller')} ${this.giftCard.name}`,
    update: `${this.$t('purchaseInfoBuyerDialog.updateTitleSeller')} ${this.giftCard.name}`
  }

  private payVatOptions = [
    { name: this.$t('payVat.yes'), value: 'vat_paid' },
    { name: this.$t('payVat.no'), value: 'vat_not_paid' },
    { name: this.$t('payVat.intracom'), value: 'vat_irrelevant' }
  ]

  private offsetOptions = [
    { name: this.$t('offset.complete'), value: 'complete_offset' },
    { name: this.$t('offset.partial'), value: 'partial_offset' },
    { name: this.$t('offset.no'), value: 'no_offset' }
  ]

  private partialModeOffsetOptions = [
    { name: this.$t('partialModeOffset.instantPartial'), value: 'instant_partial_offset' },
    { name: this.$t('partialModeOffset.differedPartial'), value: 'differed_partial_offset' },
    { name: this.$t('partialModeOffset.manualPartial'), value: 'manual_partial_offset' }
  ]

  private vatPaybackOptions = [
    { name: this.$t('vatPayBack.bankTransfer'), value: 'bank_transfer_vat_payback' },
    { name: this.$t('vatPayBack.prepaidAccount'), value: 'prepaid_account_vat_payback' },
    { name: this.$t('vatPayBack.instantPartial'), value: 'instant_partial_offset' },
    { name: this.$t('vatPayBack.no'), value: 'no_vat_payback' },
  ]

  private paymentModeOptions = [
    { name: this.$t('paymentMode.prepaid'), value: 'prepaid' },
    { name: this.$t('paymentMode.differed'), value: 'differed' }
  ]

  private deferredDeadlineOptions = [
    { name: this.$t('deferredDeadline.atInvoiceReception'), value: 'at_invoice_reception' },
    { name: this.$t('deferredDeadline.atEndOfMonth'), value: 'at_end_of_month' }
  ]

  get wholesaler(): Wholesaler {
    return this.userStore.model.manageable
  }

  async created() {
    await this.getSellers()
    !this.purchaseInfoSeller ? await this.handleCreate() : await this.handleUpdate()
  }

  private async getSellers() {
    this.sellersLoading = true

    const { data } = await Seller.page(1).per(999).all()

    this.sellers = data
    this.sellersLoading = false
  }

  private async getRateCards() {
    this.rateCardsLoading = true

    const { data } =
      await RateCard
        .includes(['rates'])
        .where({ manageableId: this.tempData.seller.id })
        .page(1)
        .per(999)
        .order({ createdAt: 'desc' })
        .all()

    if (this.dialogStatus === 'create')
      this.tempData.rate.rateCard = data.length > 0 ? data[0] : new RateCard({ id: '' })
    this.rateCardsLoading = false
  }

  private async handleCreate() {
    this.setTempData()
    this.dialogStatus = 'create'
    await this.getRateCards()
    this.$nextTick(() => {
      (this.$refs.purchaseInfoSellerForm as Form).clearValidate()
    })
  }

  private async handleUpdate() {
    this.tempData = this.purchaseInfoSeller!.dup()

    this.dialogStatus = 'update'
    await this.getRateCards()

    this.$nextTick(() => {
      (this.$refs.purchaseInfoSellerForm as Form).clearValidate()
    })
  }

  private handleSelectSeller(sellerId: string): void {
    const seller = this.sellers.find((seller: Seller) => seller.id === sellerId)
    this.tempData.seller = seller!.dup()
    this.getRateCards()
  }

  createOrUpdate() {
    (this.$refs.purchaseInfoSellerForm as Form).validate(async(valid) => {
      this.loading = true
      if (valid) {

        try {
          await this.manageSellerRate()

          await this.tempData.save({ with: ['giftCard.id', 'seller.id', 'wholesaler.id', 'rate.id'] })

          if (this.dialogStatus === 'create') {
            this.$emit('created', this.tempData.dup())
            this.$notify({
              title: this.$t('purchaseInfoBuyerDialog.form.notification.successSeller.title') as string,
              message: this.$t('purchaseInfoBuyerDialog.form.notification.successSeller.subtitle') as string,
              type: 'success',
              duration: 2000
            })
          } else {
            this.$emit('update', this.tempData)
            this.$notify({
              title: this.$t('purchaseInfoBuyerDialog.form.notification.updateSeller.title') as string,
              message: this.$t('purchaseInfoBuyerDialog.form.notification.updateSeller.subtitle') as string,
              type: 'success',
              duration: 2000
            })
          }
          this.setTempData()
          this.$emit('close')
        } catch (err) {
          this.$notify({
            title: this.$t('purchaseInfoBuyerDialog.form.notification.errorSeller.title') as string,
            message: this.$t('purchaseInfoBuyerDialog.form.notification.errorSeller.subtitle') as string,
            type: 'error',
            duration: 2000
          })
          this.loading = false
        }
      }
      this.loading = false
    })
  }

  private async manageSellerRate(): Promise<void> {
    const rateCard_ = this.tempData.rate.rateCard.dup()
    let rate_ = this.tempData.rate

    const rateExist = rateCard_.rates.find((rate: Rate) =>
      Number(rate.amount.toFixed(2).replace(/[.,]0+$/, '')) === Number(rate_.amount.toFixed(2).replace(/[.,]0+$/, '')))

    if (rateExist) {
      this.tempData.rate = rateExist.dup()
    } else {
      await rate_.save({ with: 'rateCard.id' })
      this.tempData.rate = rate_.dup()
    }
  }

  setTempData() {
    this.tempData = new PurchaseInfo({
      buyer: new Buyer({ id: '' }),
      wholesaler: this.wholesaler.dup(),
      giftCard: this.giftCard.dup(),
      seller: this.sellers[0].dup(),
      rate: new Rate({
        id: '',
        requirementOperator: 'lt_or_eq',
        requirementValue: null,
        amount: 0,
        rateCard: new RateCard({ id: ''})
      }),
      paymentMode: 'prepaid',
      payVat: 'vat_paid',
      offset: 'no_offset',
      vatPayback: 'no_vat_payback',
      apiEnabled: false
    })
  }
}
