
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Form as ElForm } from 'element-ui/types/element-ui'
import {
  CardDistribution,
  Customer,
  CustomerOrder,
  GiftCard,
  Sku,
  Program,
  GiftCardDiscount,
  CardStock
} from '@/models'

import KRGlue from '@lyracom/embedded-form-glue'

@Component({
  name: 'Purchase',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue')
  }
})
export default class extends Vue {
  private giftCards: GiftCard[] = []
  private giftCardsLoading = false
  private paginationGiftCards = {
    page: 1,
    perPage: 999
  }

  private loadingCreate = false
  private displayPayment = false
  private orderDetails = true

  private queryOptions: Customer[] = []
  private queryValue: Customer = new Customer()
  private queryLoading = false
  private customerCreate = false
  private customerExist = false

  private programs: Program[] = []
  private programsLoading = false

  private order: CustomerOrder = new CustomerOrder({ giftCard: new GiftCard({ id: '' }) })
  private cardDistributions: CardDistribution[] = []

  kr!: any
  step = 0
  private cardSelect = {
    'box-shadow': '0px 0px 0px 3px #1890FF inset',
    'background-color': '#ecf8ff',
    'font-weight': 600,
    color: '#1890FF'
  }

  rules: any = {
    customerFirstname: [
      { required: this.customerCreate, message: 'Le prénom du client est requis', trigger: 'blur' }
    ],
    customerLastname: [
      { required: this.customerCreate, message: 'Le nom du client est requis', trigger: 'blur' }
    ],
    customerEmail: [
      { required: this.customerCreate, type: 'email', message: 'L\'email du client est requis', trigger: 'blur' }
    ],
    programId: [
      { required: true, message: this.customerCreate ? 'Le programme de remise est requis' : 'La sélection d\'un client est requis', trigger: 'blur' }
    ],
    'giftCard.id': [
      { required: true, message: 'L\'enseigne est requise', trigger: 'blur' }
    ],
    amount: [
      { required: true, message: 'Le montant est requis', trigger: 'blur' }
    ]
  }

  get canChooseGiftCard() {
    return this.queryValue?.program?.id || this.order?.programId
  }

  get sortSkus(): Sku[] {
    return this.order.giftCard.skus.sort((a: any, b: any) => a.faceValue - b.faceValue)
  }

  created() {
    this.getPrograms()
  }

  private async getPrograms() {
    this.programsLoading = true
    const { data } = await Program
      .page(1)
      .per(100)
      .stats({ total: 'count' })
      .all()

    this.programs = data
    this.programsLoading = false
  }

  @Watch('order.programId')
  private async getGiftCards() {
    this.giftCardsLoading = true

    const { data } = await GiftCard
      .includes([{ skus: 'cardStock' }, 'discounts'])
      .merge({
        discounts: GiftCardDiscount.byProgramId(this.queryValue?.program?.id || this.order?.programId),
        skus: Sku.where({ faceValueFree: false })
      })
      .select(['name', 'logoSquare'])
      .selectExtra(['apiEnabled'])
      .per(this.paginationGiftCards.perPage)
      .page(this.paginationGiftCards.page)
      .order('name')
      .all()

    this.giftCards.push(...data)
    this.handleGiftCardSelect(this.giftCards[0].id)

    this.giftCardsLoading = false
  }

  @Watch('step')
  hardReload(val: any) {
    if (val === 4) {
      setTimeout(() => {
        const loading = this.$loading({
          lock: true,
          text: 'Redirection automatique...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        setTimeout(() => {
          loading.close()
          this.$router.push({ name: 'Orders' })
          this.$router.go(0)
        }, 2000)
      }, 2000)
    }
  }

  queryCustomer(query: string) {
    if (query) {
      this.queryLoading = true
      setTimeout(async() => {
        const { data } = await Customer
          .where({ email: { match: query } })
          .includes(['program'])
          .page(1)
          .per(10)
          .order('email')
          .all()

        this.queryOptions = { ...data }
        this.queryLoading = false
      }, 200)
    } else {
      this.queryOptions = []
    }
  }

  private handleGiftCardSelect(giftCardId: string) {
    const selected = this.giftCards.find(giftCard => giftCard.id === giftCardId)

    if (selected) {
      this.order.giftCard = selected
      this.order.amount = 0
      this.cardDistributions = []
      this.cardDistributionsBySku(this.order.giftCard.skus)
    }
  }

  private handleCardQuantity() {
    this.order.amount = this.cardDistributions.reduce((acc: number, current: CardDistribution): number => {
      return acc + (current.amount * current.quantity)
    }, 0)
  }

  resetAmount() {
    if (this.orderDetails) this.cardDistributionsBySku(this.order.giftCard.skus)
    this.order.amount = 0
  }

  private cardDistributionsBySku(skus: Sku[]) {
    this.cardDistributions = []
    const skuList = skus.sort((a: any, b: any) => b.faceValue - a.faceValue).reverse()

    for (const sku of skuList) {
      this.cardDistributions.push(new CardDistribution({
        quantity: 0,
        amount: sku.faceValue,
        sku: sku
      }))
    }
  }

  formatCardDistribution(): CardDistribution[] {
    const tempList = this.cardDistributions

    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].quantity === 0) {
        tempList.splice(i, 1)
        i--
      }
    }

    return tempList
  }

  private displayCardStock(cardStock: CardStock): string {
    if ((!this.order.giftCard.apiEnabled && cardStock && cardStock.inStockCount === 0) || !cardStock) return '<span class="text-warning">Précommande</span>'
    return `<span class="text-success">${this.order.giftCard.apiEnabled ? 'Illimité' : cardStock.inStockCount > 1 ? `${cardStock.inStockCount} cartes` : `${cardStock.inStockCount} carte`}</span>`
  }

  async formatUserAlreadyExit() {
    this.order = new CustomerOrder({
      ...this.order,
      customerEmail: this.queryValue.email,
      programId: this.queryValue.program.id
    })
    await this.getGiftCards()
    if (this.order.giftCard.id) this.handleGiftCardSelect(this.order.giftCard.id)
  }

  private async resetOrderData() {
    this.displayPayment = false
    await this.kr.removeForms()
    await this.order.destroy()

    this.order = new CustomerOrder({
      giftCard: this.order.giftCard,
      amount: this.order.amount,
      customerEmail: this.order.customerEmail,
      customerFirstname: this.order.customerFirstname,
      customerLastname: this.order.customerLastname,
      programId: this.order.programId,
      cardDistributions: this.order.cardDistributions
    })
    if (this.orderDetails) this.resetCardDistributions()
    this.step--
  }

  resetCardDistributions() {
    const tempList = this.cardDistributions
    this.cardDistributions = []

    for (const card of tempList) {
      this.cardDistributions.push(new CardDistribution({
        quantity: card.quantity,
        amount: card.sku.faceValue,
        sku: card.sku
      }))
    }
  }

  async submitForm(): Promise<any> {
    (this.$refs.purchaseForm as ElForm).validate(async(valid) => {
      if (valid) {
        this.loadingCreate = true

        try {

          if (this.orderDetails) this.order.cardDistributions = this.formatCardDistribution()
          await this.order.save({ with: ['giftCard.id', { cardDistributions: 'sku' }] })
          this.createPaymentForm(this.order.paymentFormToken)
        } catch (err) {
          this.loadingCreate = false
        }
      }
    })
  }

  createPaymentForm(formToken: string): void {
    this.kr.setFormToken(formToken)
    this.kr.addForm('#paymentForm')
      .then(({ KR, result }: any) => KR.showForm(result.formId))
      .then(({ KR }: any) => {
        KR.onFormReady(() => {
          this.loadingCreate = false
          this.displayPayment = true
          this.step++
        })
      })
  }

  mounted() {
    KRGlue.loadLibrary('https://api.systempay.fr', `${process.env.VUE_APP_SYSTEMPAY_PUBLIC_KEY}`)
      .then(async({ KR }: any) => {
        await KR.setFormConfig({
          'kr-language': 'fr-FR',
          'kr-hide-debug-toolbar': true
        })

        await KR.onSubmit((response: any) => {
          if (response.clientAnswer.orderStatus === 'PAID') {
            KR.removeForms()
            this.$notify({
              title: 'Commande créée',
              message: 'Le paiement a été correctement enregistré',
              type: 'success',
              duration: 2000
            })
            this.step++
            // this.$router.push({ name: 'Orders' }).catch(err => err)
          } else {
            this.$notify({
              title: 'Commande non créée',
              message: 'Une erreur est survenue lors du paiement',
              type: 'error',
              duration: 2000
            })
          }
        })
        this.kr = KR
      })
  }
}
