
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PurchaseInfo } from '@/models'

@Component({
  name: 'GeneralInformationTable',
  components: {
    RateScheduleCard: () => import('./RateScheduleCard.vue'),
    RateSchedulePopover: () => import('./RateSchedulePopover.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private purchaseInfo!: PurchaseInfo

  private payVatText: any = {
    vat_paid: this.$t('payVat.yes'),
    vat_not_paid: this.$t('payVat.no'),
    vat_irrelevant: this.$t('payVat.intracom')
  }

  private deferredDeadlineText = {
    at_invoice_reception: this.$t('deferredDeadline.atInvoiceReception'),
    at_end_of_month: this.$t('deferredDeadline.atEndOfMonth')
  }

  private apiEnabledText: any = {
    true: this.$t('payVat.yes'),
    false: this.$t('payVat.no'),
  }

  private offsetText: any = {
    complete_offset: this.$t('offset.complete'),
    partial_offset: this.$t('offset.partial'),
    no_offset: this.$t('offset.no')
  }

  private vatPayBackText: any = {
    bank_transfer_vat_payback: this.$t('vatPayBack.bankTransfer'),
    prepaid_account_vat_payback: this.$t('vatPayBack.prepaidAccount'),
    instant_partial_offset: this.$t('vatPayBack.instantPartial'),
    in_commission_vat_payback: this.$t('vatPayBack.inCommission'),
    no_vat_payback: this.$t('vatPayBack.no')
  }

  private paymentModeText: any = {
    prepaid: this.$t('paymentMode.prepaid'),
    differed: this.$t('paymentMode.differed')
  }
}
