
import { Component, Vue } from 'vue-property-decorator'
import { GiftCard } from '@/models'

@Component({
  name: 'PurchaseInfosHeader'
})
export default class extends Vue {
  private totalGiftCards = 0

  async created(): Promise<void> {
    await this.getGiftCardsCount()
  }

  private async getGiftCardsCount(): Promise<void> {
    const { meta } = await GiftCard.stats({ total: 'count' }).all()

    this.totalGiftCards = meta.stats.total.count
  }
}
